@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

// Additional content container styles
$additional-content-padding: 0 !default;

.productDetailsPanel {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  border-bottom: 1px solid $color-border;
  margin-bottom: $space-lg;

  @include lg-min {
    justify-content: space-between;
  }

  // :where selector has 0 specificity, so it can be overridden by any other selector
  :where(.carouselContainer) {
    width: 100%;
    padding: $space-xxs;

    @include md-min {
      display: block;
      width: calc(50% - ($space-md * 2));
      margin: 0 $space-md;
      padding: $space-md;
    }

    @include lg-min {
      padding: 0;
      margin: 0;
      width: calc(60% - ($space-md * 2));
    }
  }

  :where(.variationPanelContainer) {
    width: 100%;
    padding: $space-xs;

    @include md-min {
      width: calc(40% - $space-xs);
      display: block;
      margin-left: $space-xs;
      border-left: 1px solid $color-border;
      padding: $space-md;
    }
  }
}

.quickview {
  &.productDetailsPanel {
    justify-content: center;
    border-bottom: none;
    margin-bottom: $space-xl;
  }

  :where([class*="name"], [class*="price"], [class*="top"]) {
    order: -1;
  }

  :where(.carouselContainer) {
    margin: 0 24px;
    margin-left: 0;
    padding-inline: 0;

    @include lg-min {
      width: calc(50% - ($space-md * 2));
    }
  }

  :where(.variationPanelContainer) {
    width: 100%;
    margin: 0 0 $space-lg;
    border-left: none;
    padding: 0;

    // This is necessary due to how the variation panel is ordered in the DOM
    @include sm-max {
      display: contents;

      > :nth-last-child(2) {
        width: 100%;
      }

      > :first-child {
        display: contents;

        > div {
          width: 100%;
        }
      }
    }

    @include md-min {
      display: block;
      width: calc(50% - $space-xs);
    }
  }
}

.additionalContentContainer {
  border: none;
}

.tileBuy {
  width: 100%;

  @include lg-min {
    flex-grow: 1;
  }
}

.additionalContent {
  padding: $additional-content-padding;
}

.fullDetails {
  margin: $space-sm 0;
  text-align: center;
  width: 100%;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  > a {
    color: $color-font-base;
  }
}

.applePayContainer {
  margin-top: $space-xxs;
}
